<i18n>
{
  "de": {
    "pageTitle": "Zugangsrechte verwalten",
    "deleteUserPendingMessage": "Benutzerzugang wird entfernt...",
    "noUsers": "Keine Benutzer-/innen",
    "addToUser": "Benutzer/-in hinzufügen",
    "deleteButtonLabel": "Zugriff entziehen",
    "deleteConfirmation": "Wollen Sie Benutzer/-in \"{name}\" wirklich den Zugriff entziehen?",
    "returnButton": "Zurück"
  }
}
</i18n>

<template>
  <main-layout :ready="portfolioReady && portfolioUsersReady" class="admin-portfolio-users-page">
    <template #default>
      <p v-if="deleteUserError">{{ deleteUserError }}</p>
      <p v-else-if="deleteUserPending">{{ $t('deleteUserPendingMessage') }}</p>
      <template v-else>
        <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
        <button-wrapper>
          <div>
            <router-link class="button" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id }}">{{ $t('returnButton') }}</router-link>
          </div>
        </button-wrapper>
        <br />
        <ul v-if="hasUsers" class="users-list" :class="usersListClasses">
          <portfolio-user-row class="user-list-row" :user="user" v-for="user in users" :key="user.id">
            <a href="#" @click.prevent="onRemoveUser(user)"><img src="/icons/trash-2.svg" :title="$t('deleteButtonLabel')"></a>
          </portfolio-user-row>
        </ul>
        <p v-else>{{ $t('noUsers') }}</p>
        <button-wrapper>
          <div>
            <router-link class="button" :to="{ name: 'adminAddUserToPortfolio', params: { portfolio_id: portfolio.id } }">{{ $t('addToUser') }}</router-link>
          </div>
        </button-wrapper>
      </template>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'
import PortfolioUsersMixin from '@/pages/vuex-mixins/PortfolioUsersMixin'

import ButtonWrapper from '@/components/shared/ButtonWrapper'
import PortfolioUserRow from '@/components/admin-portfolios/PortfolioUserRow'

import { mapActions } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioUsersMixin // Provides: portfolio.users, portfolioUsersLoading, portfolioUsersReady
  ],
  data () {
    return {
      deleteUserError: null,
      deleteUserPending: false
    }
  },
  computed: {
    users () {
      return this.portfolio && this.portfolio.users
    },
    hasUsers () {
      return Boolean(this.users && this.users.length)
    },
    hasAdmins () {
      return Boolean((this.users || []).find(u => u.admin))
    },
    usersListClasses () {
      return {
        'with-admin-column': this.hasAdmins
      }
    }
  },
  watch: {
    portfolio (val, oldVal) {
      const { name } = val || {}
      this.name = name
    }
  },
  methods: {
    ...mapActions({
      loadUsersById: 'portfolio/loadUsersById',
      removePortfolioUser: 'portfolio/removePortfolioUser'
    }),
    async onRemoveUser (user) {
      const confirmationText = this.$t('deleteConfirmation', { name: `${user.first_name} ${user.last_name}` })
      if (confirm(confirmationText)) {
        try {
          this.deleteUserPending = true
          await this.removePortfolioUser({ portfolioId: this.portfolio.id, userId: user.id })
        } catch (error) {
          this.deleteUserError = error
        }
        this.deleteUserPending = false
      }
    }
  },
  components: {
    MainLayout,
    ButtonWrapper,
    PortfolioUserRow
  }
}
</script>

<style>
.admin-portfolio-users-page .users-list {
  display: grid;
  grid-template-columns: repeat(5, auto);
  /* grid-auto-flow: column;
  grid-auto-columns: auto; */
  gap: var(--spacing-l);
  row-gap: var(--spacing-s);
  justify-content: start;
  justify-items: start;
  align-items: baseline;
}

.admin-portfolio-users-page .users-list.with-admin-column {
  grid-template-columns: repeat(5, auto);
}

.admin-portfolio-users-page .users-list .portfolio-user-row {
  display: contents;
}
</style>
